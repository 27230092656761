/* style.css */
body {
	background-color: rgb(255, 255, 255);
}

* {
	box-sizing: border-box;
}

.container {
	height: 100vh;
	display: flex;
	flex-direction: column;
}

.spacer {
	flex-grow: 1;
}

.top-spacer {
	flex-grow: 0.5;
}

.container .header-container {
	background-color: #f6f6f6e0;
	backdrop-filter: blur(10px);
	border-radius: 20px;
	padding: 20px;
    margin-bottom: 40px;
}

.container h1 {
	font-family: 'white-garden';
	text-align: center;
    font-size: 50px;
    font-weight: 300;
	margin: 0 auto;
}

.container h2 {
	text-align: center;
	color: rgb(0, 0, 0);
	font-size: 25px;
	padding: 15px;
	margin: 0 auto;
}

form {
	background-color: #f6f6f6e0;
	backdrop-filter: blur(10px);
	padding: 50px;
	border-radius: 20px;
	display: block;
	width: 550px;
}

form .input-group {
	margin-bottom: 25px;
}

form label {
	display: block;
	margin-top: 5px;
	margin-bottom: 10px;
}

form input {
	padding: 12px 20px;
	width: 100%;
	border: 1px solid #ccc;
	border-radius: 15px;
	height: 50px;
}

.submit-btn {
	width: 100%;
	border: none;
	background: #4f7462;
	font-size: 18px;
	color: white;
	border-radius: 15px;
	padding: 20px;
	text-align: center;
	cursor: pointer;
	transition: background 0.1s ease;
	display: flex;
}

.submit-btn:disabled, .submit-btn[disabled] {
	cursor: auto;
	background-color: #dbdbdb !important;
}
.submit-btn:disabled:hover, .submit-btn[disabled]:hover {
	background-color: #dbdbdb !important;
}

.submit-btn:hover {
	background: #628f79;
	transition: background 0.1s ease;
}

.error-text {
	color: #ce0202;
	font-size: smaller;
}

.top-img {
	position: fixed;
	top: 0;
	left: 0;
	transform: rotateZ(180deg);
	z-index: 1;
	min-width: 100%;
}

.bottom-img {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 1;
	min-width: 100%;
}

.inner-container {
	position: relative;
	z-index: 2;
	margin: 0 auto;
	max-width: 550px;
	flex-grow: 1;
}

.info-text {
	min-height: 17px;
}

.info-text.success {
	color: #0eb363 !important;
}

@media only screen and (max-width: 600px) {
	.inner-container {
		max-width: none;
	}
	form {
		width: auto;
	}
	.top-spacer {
		flex-grow: 0;
	}
	.spacer {
		flex-grow: 0;
	}
	.inner-container {
		margin: 0;
	}
	.inner-container h1 {
		margin-top: 0;
	}
	.container .header-container {
		border-radius: 0;
	}
	.inner-container form {
		border-radius: 0;
	}
}

.upload-label {
	margin: 0 auto;
    padding-left: 20px;
}

.loader {
	border: 2px solid #fff; /* Light grey */
	border-top: 2px solid #d7453d; /* Blue */
	border-radius: 50%;
	width: 20px;
	height: 20px;
	animation: spin 2s linear infinite;
	opacity: 0;
	transition: opacity 0.05s ease;
}
.loader.loading {
	opacity: 1 !important;
	transition: opacity 0.05s ease;
}

@keyframes spin {
	0% { 
		transform: rotate(0deg); 
		border-top: 2px solid #ec281d;
	}
	100% { 
		transform: rotate(360deg);
		border-top: 2px solid #d7453d;
	 }
}

.footer {
	height: 100px;
	background-color: #f6f6f6e0;
	backdrop-filter: blur(10px);
	z-index: 2;
	position: relative;
	padding: 1rem;
	display: flex;
	justify-content: space-between;
}

.footer-item {
	display: block;
	color: #000;
	padding: 3px 0;
}