@font-face {
  font-family: 'white-garden';
  src: url('WhiteGarden-Regular.ttf');
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Josefin Sans", sans-serif;
  	font-optical-sizing: auto;
  	/* font-weight: <weight>;*/
  	font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
